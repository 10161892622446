// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cn-commitment-jsx": () => import("./../../../src/pages/cn/commitment.jsx" /* webpackChunkName: "component---src-pages-cn-commitment-jsx" */),
  "component---src-pages-cn-company-profile-jsx": () => import("./../../../src/pages/cn/company-profile.jsx" /* webpackChunkName: "component---src-pages-cn-company-profile-jsx" */),
  "component---src-pages-cn-philosophy-jsx": () => import("./../../../src/pages/cn/philosophy.jsx" /* webpackChunkName: "component---src-pages-cn-philosophy-jsx" */),
  "component---src-pages-cn-product-brand-jsx": () => import("./../../../src/pages/cn/product-brand.jsx" /* webpackChunkName: "component---src-pages-cn-product-brand-jsx" */),
  "component---src-pages-cn-toppage-jsx": () => import("./../../../src/pages/cn/toppage.jsx" /* webpackChunkName: "component---src-pages-cn-toppage-jsx" */),
  "component---src-pages-company-activities-jsx": () => import("./../../../src/pages/company-activities.jsx" /* webpackChunkName: "component---src-pages-company-activities-jsx" */),
  "component---src-pages-company-locations-jsx": () => import("./../../../src/pages/company-locations.jsx" /* webpackChunkName: "component---src-pages-company-locations-jsx" */),
  "component---src-pages-company-profiles-department-introduction-jsx": () => import("./../../../src/pages/company-profiles/department-introduction.jsx" /* webpackChunkName: "component---src-pages-company-profiles-department-introduction-jsx" */),
  "component---src-pages-company-profiles-jsx": () => import("./../../../src/pages/company-profiles.jsx" /* webpackChunkName: "component---src-pages-company-profiles-jsx" */),
  "component---src-pages-company-vision-jsx": () => import("./../../../src/pages/company-vision.jsx" /* webpackChunkName: "component---src-pages-company-vision-jsx" */),
  "component---src-pages-en-commitment-jsx": () => import("./../../../src/pages/en/commitment.jsx" /* webpackChunkName: "component---src-pages-en-commitment-jsx" */),
  "component---src-pages-en-company-profile-jsx": () => import("./../../../src/pages/en/company-profile.jsx" /* webpackChunkName: "component---src-pages-en-company-profile-jsx" */),
  "component---src-pages-en-philosophy-jsx": () => import("./../../../src/pages/en/philosophy.jsx" /* webpackChunkName: "component---src-pages-en-philosophy-jsx" */),
  "component---src-pages-en-product-brand-jsx": () => import("./../../../src/pages/en/product-brand.jsx" /* webpackChunkName: "component---src-pages-en-product-brand-jsx" */),
  "component---src-pages-en-toppage-jsx": () => import("./../../../src/pages/en/toppage.jsx" /* webpackChunkName: "component---src-pages-en-toppage-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiry-faq-page-jsx": () => import("./../../../src/pages/inquiry/faq-page.jsx" /* webpackChunkName: "component---src-pages-inquiry-faq-page-jsx" */),
  "component---src-pages-inquiry-jsx": () => import("./../../../src/pages/inquiry.jsx" /* webpackChunkName: "component---src-pages-inquiry-jsx" */),
  "component---src-pages-inquiry-privacy-policy-jsx": () => import("./../../../src/pages/inquiry/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-inquiry-privacy-policy-jsx" */),
  "component---src-pages-ourbusiness-bakery-shop-jsx": () => import("./../../../src/pages/ourbusiness/bakery-shop.jsx" /* webpackChunkName: "component---src-pages-ourbusiness-bakery-shop-jsx" */),
  "component---src-pages-ourbusiness-flower-dep-jsx": () => import("./../../../src/pages/ourbusiness/flower-dep.jsx" /* webpackChunkName: "component---src-pages-ourbusiness-flower-dep-jsx" */),
  "component---src-pages-ourbusiness-francois-jsx": () => import("./../../../src/pages/ourbusiness/francois.jsx" /* webpackChunkName: "component---src-pages-ourbusiness-francois-jsx" */),
  "component---src-pages-ourbusiness-menshoku-jsx": () => import("./../../../src/pages/ourbusiness/menshoku.jsx" /* webpackChunkName: "component---src-pages-ourbusiness-menshoku-jsx" */),
  "component---src-pages-ourbusiness-nbk-jsx": () => import("./../../../src/pages/ourbusiness/nbk.jsx" /* webpackChunkName: "component---src-pages-ourbusiness-nbk-jsx" */),
  "component---src-pages-ourbusiness-overseas-venture-jsx": () => import("./../../../src/pages/ourbusiness/overseas-venture.jsx" /* webpackChunkName: "component---src-pages-ourbusiness-overseas-venture-jsx" */),
  "component---src-pages-ourbusiness-sweets-jsx": () => import("./../../../src/pages/ourbusiness/sweets.jsx" /* webpackChunkName: "component---src-pages-ourbusiness-sweets-jsx" */),
  "component---src-pages-pdf-leaflet-jsx": () => import("./../../../src/pages/pdf/leaflet.jsx" /* webpackChunkName: "component---src-pages-pdf-leaflet-jsx" */),
  "component---src-pages-recruitment-jsx": () => import("./../../../src/pages/recruitment.jsx" /* webpackChunkName: "component---src-pages-recruitment-jsx" */),
  "component---src-pages-voice-of-employees-jsx": () => import("./../../../src/pages/voice-of-employees.jsx" /* webpackChunkName: "component---src-pages-voice-of-employees-jsx" */),
  "component---src-templates-information-blog-template-jsx": () => import("./../../../src/templates/information-blog-template.jsx" /* webpackChunkName: "component---src-templates-information-blog-template-jsx" */),
  "component---src-templates-voice-of-employees-template-jsx": () => import("./../../../src/templates/voice-of-employees-template.jsx" /* webpackChunkName: "component---src-templates-voice-of-employees-template-jsx" */)
}

